import axios from 'axios';
import { getIdToken } from 'firebase/auth'

let user = null;

const api = axios.create({
    //baseURL: 'http://127.0.0.1:5001/e-pdx-b9d3b/us-central1/'
    baseURL: 'https://us-central1-e-pdx-b9d3b.cloudfunctions.net/'
});

api.interceptors.request.use(async config => {
    if (user) {
        const token = await getIdToken(user);
        if (token) {
            config.headers.Authorization = token;
        }
    }
    return config;
})

export function defineApiUser(newUser) {
    user = newUser;
}

export default api;