import boxOfficeTime from "./pages/presentation/reports/boxOfficeTime";
import { BASIC_ROLE } from "./types/roles";

export const homeMenu = {
  intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
  bootstrap: {
    id: 'bootstrap',
    text: 'Bootstrap Components',
    path: '#bootstrap',
    icon: 'BootstrapFill',
    subMenu: null,
  },
  storybook: {
    id: 'storybook',
    text: 'Storybook',
    path: '#storybook',
    icon: 'CustomStorybook',
    subMenu: null,
  },
  formik: {
    id: 'formik',
    text: 'Formik',
    path: '#formik',
    icon: 'CheckBox',
    subMenu: null,
  },
  apex: {
    id: 'apex',
    text: 'Apex Charts',
    path: '#apex',
    icon: 'AreaChart',
    subMenu: null,
  },
};

export const authPages = {
  signUp: {
    id: 'signUp',
    text: 'Sign Up',
    path: '/sign-up',
    icon: 'PersonAdd',
  },
  login: {
    id: 'login',
    text: 'Login',
    path: '/',
    icon: 'Login',
  },
  logout: {
    id: 'logout',
    text: 'Sair',
    path: '/logout',
    icon: 'Logout',
  },
  profile: {
    id: 'profile',
    text: 'perfil',
    path: '/profile',
    icon: 'profile',


  },
  page404: {
    id: 'Page404',
    text: '404 Page',
    path: '/404',
    icon: 'ReportGmailerrorred',
  },
}
export const calendarMenu = {
  calendar: {
    id: 'calendar',
    text: 'Calendario',
    path: '/calendar',
    icon: 'CalendarToday',
  },
};

export const othersMenu = {
  operatorAutoRegister: {
    id: 'operatorAutoRegister',
    text: 'Cadastro',
    path: '/cadastro-operador',
    icon: 'PersonAdd',
  },
  preRegister: {
    id: 'preRegister',
    text: 'Cadastro',
    path: '/preRegister',
    icon: 'PersonAdd',
  },
  termsOfUse: {
    id: 'termsOfUse',
    text: 'Cadastro',
    path: '/privacidade',
    icon: 'PersonAdd',
  }
};

export const financialMenu = {
  financial: {
    id: 'financial',
    text: 'Financeiro',
    path: '/financial',
    icon: 'Dashboard',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.MANAGER, BASIC_ROLE.COORDINATOR, BASIC_ROLE.STOCKIST],
  },
};
export const topMenu = {
  admContabil: {
    id: 'admContabil',
    text: 'ADM Contábil',
    path: '/admContabil',
    icon: 'AttachMoney',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.FINANCIAL_MANAGER],
  },
  contEvent: {
    id: 'contEvent',
    text: 'Evento Contábil',
    path: '/contEvent',
    icon: 'Bento',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.FINANCIAL_MANAGER],
  },
  contracts: {
    id: 'contracts',
    text: 'Meus Contratos',
    path: '/contracts',
    icon: 'Assignment',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.CONTRACT_MANAGER],
  },
}


export const demoPages = {
  pageLayout: {
    id: 'registers',
    text: 'Cadastros',
    path: 'page-layouts',
    icon: 'AddBox',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.MANAGER, BASIC_ROLE.COORDINATOR],
    subMenu: {
      establishment: {
        id: 'establishment',
        text: 'Estabelecimentos',
        path: 'registers/establishment',
        icon: 'House',
        roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN],
      },
      users: {
        id: 'users-register',
        text: 'Usuários',
        path: 'registers/users',
        icon: 'Person',
        roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.COORDINATOR, BASIC_ROLE.MANAGER, BASIC_ROLE.ADMIN],
      },
      usersOperators: {
        id: 'users-operators-register',
        text: 'Operadores',
        path: 'registers/operators',
        icon: 'SupervisorAccount',
        roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.COORDINATOR, BASIC_ROLE.MANAGER, BASIC_ROLE.ADMIN],
      },
      sellers: {
        id: 'seller-register',
        text: 'Vendedores',
        path: 'registers/sellers',
        icon: 'PointOfSale',
        roles: [BASIC_ROLE.SUPER_ADMIN],
      },
      stockist: {
        id: 'stockist-register',
        text: 'Estoquistas',
        path: 'registers/stockist',
        icon: 'AllInbox',
        roles: [BASIC_ROLE.SUPER_ADMIN],
      },
      contractManager: {
        id: 'contractManager-register',
        text: 'Gestor de Contratos',
        path: 'registers/contractManager',
        icon: 'ImportContacts',
        roles: [BASIC_ROLE.SUPER_ADMIN],
      },
      financialManager: {
        id: 'financialManager-register',
        text: 'Gestor Financeiro',
        path: 'registers/financialManager',
        icon: 'AttachMoney',
        roles: [BASIC_ROLE.SUPER_ADMIN],
      },
    },
  },
  establishment: {
    id: 'estab',
    text: 'Estabelecimento',
    path: 'estab',
    icon: 'House',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.MANAGER, BASIC_ROLE.COORDINATOR],
    subMenu: {
      event: {
        id: 'estab_event',
        text: 'Eventos',
        path: 'estab/event',
        icon: 'Festival',
      },
      reasons: {
        id: 'reasons',
        text: 'Justificativas',
        path: 'establishment/reasons',
        icon: 'Comment',
      },
      sectors: {
        id: 'estab_sectors',
        text: 'Setores',
        path: 'estab/sectors',
        icon: 'Store',
      },
      totems: {
        id: 'estab_totems',
        text: 'Totem',
        path: 'estab/totems',
        icon: 'SettingsCell',
      },
      totemsRecharge: {
        id: 'estab_totemsRecharge',
        text: 'Totem Recarga',
        path: 'estab/totemsRecharge',
        icon: 'Tv',
      },
      settings: {
        id: 'estab_settings',
        text: 'Configurações',
        path: 'estab/settings',
        icon: 'Settings',
      },
      // operators: {
      //   id: 'estab_operators',
      //   text: 'Operadores',
      //   path: 'estab/operators',
      //   icon: 'ConfirmationNumber',
      // },
    },
  },
  event: {
    id: 'event',
    text: 'Evento',
    path: 'event',
    icon: 'Festival',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.MANAGER, BASIC_ROLE.COORDINATOR],
    subMenu: {
      coordinators: {
        id: 'event_coordinators',
        text: 'Coordenadores',
        path: 'event/coordinators',
        icon: 'AccountCircle',
      },
      operators: {
        id: 'event_operators',
        text: 'Operadores',
        path: 'event/operators',
        icon: 'SupervisorAccount',
      },
      entrance: {
        id: 'event_entrance',
        text: 'Bilheteria',
        path: 'event/bilheteria',
        icon: 'ConfirmationNumber',
      },
      earlyConsummation: {
        id: 'earlyConsummation',
        text: 'Consumação Antecipada',
        path: 'event/earlyConsummation',
        icon: 'ConfirmationNumber',
      },
      promoterLink: {
        id: 'promoterLink',
        text: 'Links Promoter',
        path: 'event/promoterLink',
        icon: 'Share',
      },
      courtesyList: {
        id: 'courtesyList',
        text: 'Lista VIP',
        path: 'event/vipList',
        icon: 'ChecklistRtl',
      },
      accessPassword: {
        id: 'access-password',
        text: 'Senha de Acesso',
        path: 'event/accessPasswrod',
        icon: 'Security',
      },
      settings: {
        id: 'eventSettings',
        text: 'Configuração',
        path: 'event/settings',
        icon: 'Settings',
      },
      // cashier: {
      //   id: 'cashier',
      //   text: 'Caixas',
      //   path: 'event/cashier',
      //   icon: 'AttachMoney',
      //   // min_level: ACCESS_LEVEL.ADMIN,
      // },
    },
  },
  pdvMenu: {
    id: 'pdvMenu',
    text: 'PDV',
    path: '/dashboard',
    icon: 'ImportantDevices',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.MANAGER, BASIC_ROLE.COORDINATOR],
    subMenu: {
      categoryComplement: {
        id: 'categoryComplement',
        text: 'Categoria do Complemento',
        path: 'pdv/categoryComplement',
        icon: 'Bookmarks',
      },
      product: {
        id: 'product',
        text: 'Produtos',
        path: 'pdv/products',
        icon: 'TagsFill',
      },
      subCategories: {
        id: 'subCategories',
        text: 'Sub-Categorias',
        path: 'pdv/subCategories',
        icon: 'Subtract',
      },
      categories: {
        id: 'categories',
        text: 'Categorias',
        path: 'pdv/categories',
        icon: 'Category',
      },
      menus: {
        id: 'menus',
        text: 'Cardápios',
        path: 'pdv/menus',
        icon: 'MenuBook',
      },
    },
  },
  sector: {
    id: 'sector',
    text: 'Setor',
    path: 'page-layouts',
    icon: 'Store',
    subMenu: {
      product: {
        id: 'product',
        text: 'Produtos',
        path: 'sector/products',
        icon: 'TagsFill',
      },
      stockProducts: {
        id: 'stock-products',
        text: 'Estoque',
        path: 'sector/stockProducts',
        icon: 'AllInbox',
      },
      // stockComponents: {
      //   id: 'stock-components',
      //   text: 'Estoque Componente',
      //   path: 'sector/stockComponents',
      //   icon: 'Inbox',
      // },
      printers: {
        id: 'printers',
        text: 'Impressoras',
        path: 'sector/printers',
        icon: 'Printer',
        roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.MANAGER, BASIC_ROLE.COORDINATOR],
      },
      // settings: {
      //   id: 'sectorSettings',
      //   text: 'Configurações',
      //   path: 'sector/settings',
      //   icon: 'Settings',
      // },
    },
  },
  defect: {
    id: 'defect',
    text: 'Avariados',
    path: 'defect',
    icon: 'ReportProblem',
  },

  configUget: {
    id: 'configUget',
    text: 'Config uGet',
    path: 'configUget',
    icon: 'Settings',
    roles: [BASIC_ROLE.SUPER_ADMIN],
  },
  eventStatus: {
    id: 'eventStatus',
    text: 'Status Eventos',
    path: 'eventStatus',
    icon: 'EventNote',
    hide: true
  },

  // contactUs: {
  //   id: 'contactUs',
  //   text: 'Fale Conosco',
  //   path: 'contactUs',
  //   icon: 'ContactSupport',
  // },

  // documentations: {
  //   id: 'documentations',
  //   text: 'Documentação',
  //   path: 'documentations',
  //   icon: 'TextSnippet',
  // },
  reports: {
    id: 'reports',
    text: 'Relatórios',
    path: 'reports',
    icon: 'BugReport',
    roles: [BASIC_ROLE.SUPER_ADMIN, BASIC_ROLE.ADMIN, BASIC_ROLE.MANAGER, BASIC_ROLE.COORDINATOR],
    subMenu: {
      boxOfficeTime: {
        id: 'boxOfficeTime',
        text: 'Entradas por hora',
        path: 'reports/boxOfficeTime',
        icon: 'Person',
      },
      clientsReports: {
        id: 'clientsReports',
        text: 'Log de Clientes',
        path: 'reports/clientsReports',
        icon: 'BugReport',
      },
      operatorsReports: {
        id: 'operatorsReports',
        text: 'Log de Operadores',
        path: 'reports/operatorsReports',
        icon: 'BugReport',
      },
      // cashReports: {
      //   id: 'cashReports',
      //   text: 'Relatórios Caixa',
      //   path: 'reports/cashReports',
      //   icon: 'PointOfSale',
      // },
      // totemReports: {
      //   id: 'totemReports',
      //   text: 'Relatórios Totens',
      //   path: 'reports/totemReports',
      //   icon: 'PhoneIphone',
      // },
      // saleReports: {
      //   id: 'saleReports',
      //   text: 'Relatórios Vendas',
      //   path: 'reports/saleReports',
      //   icon: 'AssignmentTurnedIn',
      // },
      // reportConsumption: {
      //   id: 'consumptionReport',
      //   text: 'Consumo Movimentação',
      //   path: 'reports/reportConsumption',
      //   icon: 'Moving',
      // },
      filtroPorData: {
        id: 'filtroPorData',
        text: 'Filtro por Data',
        path: 'reports/filtroPorData',
        icon: 'EdgesensorHigh',
      },
      // stock: {
      //   id: 'stock',
      //   text: 'Estoque',
      //   path: 'reports/stock',
      //   icon: 'AllInbox',
      // },
    },
  },

};

export const productsMenu = {
  companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
  companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
  companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
  companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
